import logo from './logo.svg';
import catalog from './PadaFiyatsizKatalog.pdf'
import './App.css';

function App() {
  return (
    <object className="pdf" 
            data={catalog}
            width="100%">
    </object>
    // <iframe src='https://docs.google.com/viewer?url=https://drive.google.com/file/d/1KSMh0qR7QAke647XOPaEnYMEzEadsAzX/view?usp=drive_link&embedded=true'></iframe>
    // <div><embed src='./PadaFiyatsizKatalog.pdf' ></embed></div>
    // <div className="App">
    //   <header className="App-header">
       
    //     <p>
    //       Yakında buradayız!
    //     </p>
    //   </header>
    // </div>
  );
}

export default App;
